

import {Component, Mixins, Prop, Ref} from "vue-property-decorator";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import FormWrapper from "@/components/FormWrapper.vue";
import AdresForm from "@/components/forms/AdresForm.vue";
import Dates from "@/components/inputs/Dates.vue";
import TcKimlikNumarasi from "@/components/inputs/TcKimlikNumarasi.vue";
import IletisimTuruPicker from "@/components/pickers/IletisimTuruPicker.vue";
import UlkeTelefon from "@/components/inputs/UlkeTelefon.vue";
import TelefonNumarasi from "@/components/inputs/TelefonNumarasi.vue";
import {IletisimBilgisiTuruLabel} from "@/enum/IletisimBilgisiTuru";
import {StatusCodeHelper} from "@/helpers/StatusCodeHelper";
import {BorcluVekiliEntity} from "@/entity/BorcluVekiliEntity";
import ItemArrayManager from "@/components/common-inputs/ItemArrayManager.vue";
import {ItemArrayManagerTableHeader} from "@/interface/TableHeader";
import IletisimForm from "@/components/forms/IletisimForm.vue";


@Component({
  components: {
    IletisimForm,
    ItemArrayManager,
    TcKimlikNumarasi,
    AdresForm, FormWrapper, Dates,
    IletisimTuruPicker,
    UlkeTelefon,
    TelefonNumarasi,
  }
})
export default class BorcluVekiliForm extends Mixins(ObjectInputMixin) {
  localValue: BorcluVekiliEntity = new BorcluVekiliEntity();
  @Ref('formWrapper') formWrapper!: FormWrapper;
  @Prop({}) successOnValidate !: string | boolean;

  headers: Array<ItemArrayManagerTableHeader> = [
    {
      value: "isim",
      text: "İletişim Bilgisi Türü",
      renderer: (row) => IletisimBilgisiTuruLabel[row.iletisim_tur_id],
    },
    {
      value: "",
      text: "İletisim Bilgisi",
      renderer: (row) => row.deger,
    }
  ];

  get isSuccessOnValidate() {
    return this.successOnValidate || this.successOnValidate === "";
  }

  get isTCKNRequired(){
    if (!this.localValue.baro_numarasi)
      return true;
    else
      return false;
  }


  get isBaroNumarasiRequired(){
    if (!this.localValue.tc_kimlik_no)
      return true;
    else
      return false;
  }

  get adRule() {
    if (this.localValue.ad) {
      if (this.localValue.ad.match(/["'<>&]/g) == null)
        return true;
      else
        return `Yasak karakter. ", ', <, >, & karakterleri kullanılamaz.`;
    } else
      return 'Lütfen bu alanı doldurun.';
  }

  get soyadRule() {
    if (this.localValue.soyad) {
      if (this.localValue.soyad.match(/["'<>&]/g) == null)
        return true;
      else
        return `Yasak karakter. ", ', <, >, & karakterleri kullanılamaz.`;
    } else
      return 'Lütfen bu alanı doldurun.';
  }

  async adresUpdate(vekilId: number) {
    if (this.localValue.adres_id != null) {
      return await this.$http.put('/api/v1/adres/' + this.localValue.adres_id, this.localValue.adres).catch(error => StatusCodeHelper.error(error));
    } else if (this.localValue.adres) {
      return await this.$http.post('/api/v1/borclu-vekili/' + vekilId + '/adres', this.localValue.adres).catch(error => StatusCodeHelper.error(error));
    }
  }

  async save() {
    this.formWrapper.onFly = true;
    if (this.localValue.id) {
      let response = await this.$http.put('/api/v1/borclu-vekili/' + this.localValue.id, this.localValue).catch(error => StatusCodeHelper.error(error)).finally(() => (this.formWrapper.onFly = false));
      let vekilId = response.data.id;
      await this.adresUpdate(vekilId);
      this.$emit("onSuccess");
    } else {
      let response = await this.$http.post('/api/v1/borclu/' + this.$route.params.id + '/vekil', this.localValue).catch(error => StatusCodeHelper.error(error)).finally(() => (this.formWrapper.onFly = false));
      let vekilId = response.data.id;
      await this.adresUpdate(vekilId);
      this.$emit("onSuccess");
    }
  }

  load() {
    this.$emit("load");
  }
}
