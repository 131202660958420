
import AlacakForm from "@/components/forms/AlacakForm.vue";
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {TakipTalebiEntity} from "@/entity/TakipTalebiEntity";
import DetailDialog from "@/components/dialogs/DetailDialog.vue";
import FormDialog from "@/components/dialogs/FormDialog.vue";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import {IlamsizBelgeTuru} from "@/enum/IlamsizBelgeTuru";
import AlacakKopyalaForm from "@/components/forms/AlacakKopyalaForm.vue";
import AlacakKalemiDetayForm from "@/components/takip/form/AlacakKalemiDetayForm.vue";
import {AlacakEntity} from "@/entity/AlacakBelgeler/AlacakEntity";
import AlacakKalemiEntity from "@/entity/AlacakBelgeler/AlacakKalemiEntity";
import {ParaBirimiEntity} from "@/entity/ParaBirimiEntity";
import TakipKurForm from "@/components/forms/TakipKurForm.vue";
import {TakipEntity} from "@/entity/TakipEntity";
import {TakipDurumu} from "@/enum/TakipDurumu";
import {ParaBirimi} from "@/enum/ParaBirimi";
import {TakipTarihliKurEntity} from "@/entity/TakipTarihliKurEntity";

@Component({
  components: {
    TakipKurForm,
    FormDialog,
    AlacakForm,
    AlacakKopyalaForm,
    AlacakKalemiDetayForm,
    DeleteDialog,
    DetailDialog,
  },
})
export default class AlacakList extends Vue {
  @Prop({required: true}) takipTalebi!: TakipTalebiEntity;
  @Prop() takip!: TakipEntity;

  alacakFormDisable:boolean = false

  takipDurumu = TakipDurumu;

  headers = [
    {text: "Borcun Sebebi", value: "alacak_belge_turu.isim", class: 'my-header-style'},
    {text: "Belge Giriş Tarihi", value: "tanzim_tarihi", class: 'my-header-style'},
    {text: "Faiz Başlangıç Tarihi", value: "faiz_baslangic_tarihi", class: 'my-header-style'},
    {text: "Açıklama", value: "aciklama", class: 'my-header-style'},
    {text: "Toplam Alacak Kalemi", value: "tutar", class: 'my-header-style'},
    {text: "İşlemler", value: "actions", sortable: false,width:500, align: 'end', class: 'header-actions-style'},
  ];



  get alacakFormDisabled(){
    if(this.takip.takip_durumu===TakipDurumu.kapali){
       return this.alacakFormDisable=true
    }
  }


  get toolbarTitle() {
    let title = 'Alacaklar'
    if (this.takipTalebi.id)
      title = 'Alacaklar ' + this.takipTalebi.takip_tipi.no + " (" + this.takipTalebi.takip_tipi.eski_no + ")";
    return title
  }

  get kiraKontratlari() {
    let kiraKontratlari: any = [];
    this.takipTalebi.alacaklar.forEach((item: any) => {
      if (item.alacak_belge_turu_id == IlamsizBelgeTuru.kiraKontrat) {
        kiraKontratlari.push(item);
      }
    });
    return kiraKontratlari;
  }

  getFaizBaslangicTarihi(alacak: AlacakEntity) {
    if (alacak.asil_alacaklar[0]?.faiz_baslangic_tarihi)
      return this.$helper.tarihStr(alacak.asil_alacaklar[0]?.faiz_baslangic_tarihi);
    else
      return null
  }

  @Watch("takipTalebi")
  onValueChange() {
    let kiraKontrati = this.kiraKontratlari;
    this.$emit("kira-kontrati", kiraKontrati);
    if (this.takip.id && this.takip.takip_tarihli_kur.length > 0)
      this.setKurAlanlari();
  }

  // TODO: İkili takipte senkronize olmayacağı için sorun çıkarabilir
  setKurAlanlari() {
    let dovizler: Array<{ para_birimi_id: number, para_birimi: ParaBirimiEntity|undefined }> = [];
    this.takip.talepler.forEach((talep: TakipTalebiEntity) => {
      talep.alacaklar.forEach((alacak: AlacakEntity) => {
        if (!dovizler.find((doviz) => (doviz.para_birimi_id == alacak.para_birimi_id)) && alacak.para_birimi_id && alacak.para_birimi_id != ParaBirimi.TRY)
          dovizler.push({para_birimi_id: alacak.para_birimi_id, para_birimi: alacak.para_birimi});
        alacak.asil_alacaklar.forEach((alacakKalemi: AlacakKalemiEntity) => {
          if (!dovizler.find((doviz) => (doviz.para_birimi_id == alacakKalemi.para_birimi_id)) && alacakKalemi.para_birimi_id && alacakKalemi.para_birimi_id != ParaBirimi.TRY)
            dovizler.push({para_birimi_id: alacakKalemi.para_birimi_id, para_birimi: alacakKalemi.para_birimi});
        })
      })
    });
    this.takip.takip_tarihli_kur.forEach((kur: TakipTarihliKurEntity, index) => {
      if (!dovizler.find((doviz) => (doviz.para_birimi_id == kur.para_birimi_id)))
        this.$http.delete('/api/v1/takip-tarihli-kur/' + kur.id).then(() => (this.takip.takip_tarihli_kur.splice(index, 1)));
    });
  }

  load() {
    let kiraKontrati = this.kiraKontratlari;
    this.$emit("kira-kontrati", kiraKontrati);
    this.$emit("load");
  }

  getAlacakKalemiToplamlari(alacak: AlacakEntity) {
    let alacakKalemiToplamlari: Array<{ para_birimi_id: number, tutar: number, para_birimi: ParaBirimiEntity|undefined }> = [];
    alacak.asil_alacaklar.forEach((alacak_kalemi: AlacakKalemiEntity) => {
      let toplamlar = alacakKalemiToplamlari.find((toplam) => (toplam.para_birimi_id == alacak_kalemi.para_birimi_id))
      if (toplamlar) {
        toplamlar.tutar += Number(alacak_kalemi.tutar);
      } else {
        alacakKalemiToplamlari.push({
          para_birimi_id: alacak_kalemi.para_birimi_id,
          tutar: Number(alacak_kalemi.tutar),
          para_birimi: alacak_kalemi.para_birimi
        });
      }
    })
    let tutarlar: Array<any> = [];
    alacakKalemiToplamlari.forEach((alacakKalemiToplami: { para_birimi_id: number, tutar: number, para_birimi: ParaBirimiEntity|undefined }) => (tutarlar.push(this.$helper.tutarStr(alacakKalemiToplami.tutar, alacakKalemiToplami.para_birimi))));
    return tutarlar;
  }
}
