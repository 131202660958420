
import {Component, Mixins, Prop, Vue, Watch} from "vue-property-decorator";
import FormDialog from "@/components/dialogs/FormDialog.vue";
import BankaHesabiForm from "@/components/forms/BankaHesabiForm.vue";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import {BankaHesabiEntity} from "@/entity/BankaHesabiEntity";
import {AdresEntity} from "@/entity/AdresEntity";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";

@Component({
  components: {FormDialog, BankaHesabiForm, DeleteDialog}
})
export default class BankaHesabiList extends Mixins(SimpleInputMixin) {
  @Prop() action!: string;
  @Prop({}) successOnValidate !: any;
  @Prop()height!:string
  @Prop()footerProps!:any

  localValue!: Array<BankaHesabiEntity>;
  iconName = 'mdi-trash-can-outline'
  color="#959595"


  headers = [
    {text: "Hesap Adı", align: "start", sortable: false, value: "isim", class: 'my-header-style'},
    {text: "Banka Adı", align: "start", sortable: false, value: "banka.isim", class: 'my-header-style'},
    {text: "İban Bilgileri", align: "start", sortable: false, value: "iban", class: 'my-header-style'},
    {text: "İşlemler", align: "end", sortable: false, value: "actions", class: 'header-islemler-style'},
  ]

  get checkLocalValue() {
    if (this.localValue) {
      return this.localValue;
    }
  }

  onAdd(item: BankaHesabiEntity) {
    if (this.action) {
      this.load();
    } else {
      this.localValue.push(item);
      this.input();
    }
  }

  onUpdate(item: BankaHesabiEntity, index: number) {
    let newArray = [...this.localValue];
    newArray[index] = item;
    this.localValue = newArray;
    this.input();
  }

  onDelete(index: number) {
    this.localValue.splice(index, 1);
    this.input();
  }

  load() {
    this.$emit("load");
  }
  isAuthorized(keySuffix: string) {
    let ap = [...this.$store.state.activeProcesses].reverse();
    let authKey = null;

    if (keySuffix[0] !== '-')
      keySuffix = '-' + keySuffix;

    if (ap[0].key === "borclu-detay")
      authKey = "borclu-banka-hesap-bilgisi" + keySuffix;
    else if (ap[0].key === "alacakli-detay")
      authKey = "muvekkil-banka-hesap-bilgisi" + keySuffix;
    else if (ap[0].key === "buro-detay")
      authKey = "buro-hesap-bilgisi" + keySuffix;
    else if (ap[0].key === "personel-detay")
      authKey = "calisan-banka-hesap-bilgisi" + keySuffix;
    else if (ap[0].key === "muvekkil-detay" || ap[0].key === "vekalet-detay")
      authKey = "muvekkil-banka-hesap-bilgisi" + keySuffix;
    else if (ap[0].key === "avukat-detay")
      authKey = "avukat-banka-hesap-bilgisi" + keySuffix;

    if (!authKey) return false;
    return this.$helper.isAuthorized(authKey)
  }
}
