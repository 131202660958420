

import {Component, Mixins, Prop, Ref} from "vue-property-decorator";
import FormWrapper from "@/components/FormWrapper.vue";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import CinsiyetPicker from "@/components/pickers/CinsiyetPicker.vue";
import TcKimlikNumarasi from "@/components/inputs/TcKimlikNumarasi.vue";
import SehirPicker from "@/components/pickers/SehirPicker.vue";
import Dates from "@/components/inputs/Dates.vue";
import AdresList from "@/components/lists/AdresList.vue";
import YabanciKimlikNumarasi from "@/components/inputs/YabanciKimlikNumarasi.vue";
import {KisiSorgulaCevap} from "@/plugins/uyap-plugin/uyap/KisiSorgula";
import {AdresTuru} from "@/enum/AdresTuru";
import {AdresEntity} from "@/entity/AdresEntity";
import BankaHesabiForm from "@/components/forms/BankaHesabiForm.vue";
import BankaHesabiList from "@/components/lists/BankaHesabiList.vue";
import YabanciUyrukSehirInput from "@/components/inputs/YabanciUyrukSehirInput.vue";

@Component({
  components: {
    YabanciUyrukSehirInput,
    BankaHesabiList,
    YabanciKimlikNumarasi,
    AdresList,
    FormWrapper,
    CinsiyetPicker,
    TcKimlikNumarasi,
    SehirPicker,
    Dates,
    BankaHesabiForm,
  },
})
export default class KisiForm extends Mixins(ObjectInputMixin) {
  @Prop() hideButton!: any;
  @Prop() muvekkilMi !: boolean;
  @Prop() isAddressEmpty !: boolean;
  @Ref('formWrapper') formWrapper!: FormWrapper;
  searchTckn: string = "";

  get adresListEmptyRule() {
    return (v: AdresEntity[]) => {
      if (v.length === 0) {
        return 'Lütfen en az bir adres giriniz.';
      } else {
        return true;
      }
    }
  }

  get isHideButton() {
    return this.hideButton || this.hideButton === "";
  }

  get yasKontrol() {
    if (this.localValue.dogum_tarihi) {
      return this.$helper.yasOgren(this.localValue.dogum_tarihi) < 18;
    } else
      return false
  }

  get yasRule() {
    if (this.localValue.dogum_tarihi) {
      let todayGetTime = new Date(new Date().toISOString().slice(0, 10)).getTime();
      let dogumTarihiGetTime = new Date(this.localValue.dogum_tarihi).getTime();
      if (todayGetTime - dogumTarihiGetTime >= 0)
        return true;
      else
        return 'Doğum tarihi gelecek bir tarih olamaz.';
    } else
      return true;
  }

  get adRule() {
    if (this.localValue.ad) {
      if (this.localValue.ad.match(/["'<>&]/g) == null)
        return true;
      else
        return `Yasak karakter. ", ', <, >, & karakterleri kullanılamaz.`;
    } else
      return 'Lütfen bu alanı doldurun.';
  }

  get soyadRule() {
    if (this.localValue.soyad) {
      if (this.localValue.soyad.match(/["'<>&]/g) == null)
        return true;
      else
        return `Yasak karakter. ", ', <, >, & karakterleri kullanılamaz.`;
    } else
      return 'Lütfen bu alanı doldurun.';
  }

  onTcKimlikNoChange() {
    if (this.localValue.tc_kimlik_no.length == 11) {
      this.$http.get('/api/v1/kisi/filter?keyword=' + this.localValue.tc_kimlik_no).then((response) => {
        if (response.data.length > 0)
          this.localValue = response.data[0];
        this.input();
      });
    } else
      this.input();
  }

  load() {
    this.$emit('load');
  }

  uyapSorgu(sorgu: KisiSorgulaCevap) {
    this.localValue.ad = sorgu.adi
    this.localValue.soyad = sorgu.soyadi
    this.localValue.cinsiyet = sorgu.cinsiyeti == "E" ? 'erkek' : 'kadin';
    let date = sorgu.dogumTarihiStr.split("/").reverse().join("-");
    this.localValue.dogum_tarihi = date;
    if (sorgu.aktifMernisAdresiSorgusu && !this.localValue.adresler.find((adres:any) => (adres.adres_tur_id == AdresTuru.mernis))) {
      let mernis = new AdresEntity();
      mernis.adres_tur_id = AdresTuru.mernis;
      this.localValue.adresler.push(mernis);
    }
    this.input();
  }

  async save() {
    this.formWrapper.onFly = true;
    if (this.localValue.id) {
      await this.$http.put('/api/v1/kisi/' + this.localValue.id, this.localValue);
      for (let i = 0; i < this.localValue.adresler.length; i++) {
        if (this.localValue.adresler[i].id)
          await this.$http.put('/api/v1/adres/' + this.localValue.adresler[i].id, this.localValue.adresler[i]);
        else
          await this.$http.post('/api/v1/kisi/' + this.localValue.id + '/adres', this.localValue.adresler[i]);
      }
      this.formWrapper.onFly = false;
      this.$emit('onSuccess');
    } else {
      let response = await this.$http.post('/api/v1/kisi/', this.localValue);
      for (let i = 0; i < this.localValue.adresler.length; i++) {
        await this.$http.post('/api/v1/kisi/' + response.data.id + '/adres', this.localValue.adresler[i]);
      }
      this.formWrapper.onFly = false;
      this.$emit('onSuccess');
    }
  }
}
